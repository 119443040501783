import { defineStore } from 'pinia'

export const useUserStore = defineStore('users', {

  state: () => {
    return {
      username: null,
      password: null,
      email: null,
      isLoggedIn: false,
      subscribed: 'NO',
    }
  },
})
