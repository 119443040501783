export function getBitcoinPrice(callback){
  let url = '/get_bitcoin_price';
  fetch(url, {method : 'GET' ,
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }}).then((response)=>{
    if (response.ok){
      response.json().then((json)=>{
        console.log (json);
        callback(json);
      });

    }
  }).catch((error)=>{
    console.log(error);
  }
  );
}
