export function getFirstThreeStocks (callback){
    let url = '/fetch_pse_stocks';
    fetch(url, {method : 'GET' ,
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }}).then((response)=>{
      if (response.ok){
        response.json().then((json)=>{
          console.log (json);
          const ctr = 3;
          let stocks = [];
          for (let i = 0; i < ctr; i++){
            if (i < json.length) {
              let data = json[0];
              let stock = data[i];
              stocks.push(stock);
            }
          }
          callback(stocks);
          }
        );
    }
  }).catch((error)=>{
    console.log(error);
  });
}
