<template>
  <div class="has-background-warning-light">

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <br>
        <br>
        <div class="tile is-child">
          <img :src="banner">
        </div>
        <br>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <br>
        <br>
        <div class="tile is-child">
          <br>
        </div>
        <br>
      </div>
    </div>
    <form @submit.prevent="add_user">

      <div class="container box">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <div class="tile is-child">
              <div class="panel notification">


                <div class="notification panel-heading is-info">
                  {{ card_title }}
                </div>
                <p class="text-muted">Create your account</p>
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                  <input class="input" type="text" placeholder="Username" required v-model="username">
                  <span class="icon is-small is-left">
                                <i class="fas fa-envelope"></i><font-awesome-icon :icon="['far', 'user']" />
                                </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                      <input class="input" type="text" placeholder="Email" required v-model="email">
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i><font-awesome-icon :icon="['far', 'envelope']" />
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                      <input class="input" type="password" placeholder="Password" required v-model="password">
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i><font-awesome-icon :icon="['fas', 'lock']" />
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input class="input" type="password" placeholder="Repeat Password" required @change="clear_password_message" v-model="repeat_password">
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i><font-awesome-icon :icon="['fas', 'lock']" />
                    </span>
                  </p>
                </div>
                <di>
                  <p class="has-text-danger">{{ password_match }}</p>
                </di>
                <button class="button is-success" type="submit">Create Account</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </form>
    <div>
      <router-link to="/login" >Already have an account? Login here</router-link>
    </div>
    <div>
      <p class="has-text-danger">{{ response_msg }}</p>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <br>
        <br>
        <div class="tile is-child">
          <br>
        </div>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data(){
    return {
      banner: require('/public/alabangvx-logo.png'),
      username: '',
      password: '',
      email: '',
      repeat_password: '',
      card_title: 'Register (All fields are required)',
      response_msg: '',
      password_match: '',
    }
  },
  methods: {
    add_user: function(){
      let url = '/add_user';
      let password = this.password;
      let repeat_password = this.repeat_password;
      if (password === repeat_password){
        fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: this.username,
            password: this.password,
            email: this.email
          })
        }).then((response) => {
          return response.text();
        }).then((data) => {
          this.response_msg = data;
          console.log(data);
        }).catch((err) => {
          this.response_msg = err;
          console.log(err);
        });
      } else {
        this.password_match = 'Passwords do not match';
      }
    },
    clear_password_message: function(){
      this.password_match = '';
    }
  }
}
</script>

<style scoped>

</style>
