<template>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent is-justify-content-center">
      <br>
      <br>
      <div class="tile is-child "></div>
        <div class="panel notification">
          <div class="panel-heading notification is-warning">
            Please enter your email address to reset your password.
          </div>
          <form v-on:submit.prevent="resetPassword">
            <div class="content">
              <div class="field">
                <p class="text-danger"></p>
                <p class="control has-icons-left has-icons-right ">

                  <input class="input" type="text" placeholder="Email" id="email" name="email" v-model="email">
                  <span class="icon is-small is-left">
                                  <i class="fas fa-envelope"></i><font-awesome-icon :icon="['far', 'envelope']" />
                                  </span>
                  <span class="icon is-small is-right">
                                  <i class="fas fa-check"></i>
                                  </span>
                </p>
              </div>
              <div><p class="text-danger">{{ response_msg }}</p></div>
              <div><button class="button is-warning" type="submit">Reset Password</button></div>
            </div>


          </form>
        </div>
      <div class="tile is-child"></div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: '',
      response_msg: '',
    };
  },
  methods: {
    resetPassword() {
      let url = '/submit_email';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.email,
        }),
      })
        .then((response) => response.text())
        .then((data) => {
          this.response_msg = data;
          console.log('Success:', data);
        })
        .catch((error) => {
          this.response_msg = error;
          console.error('Error:', error);
        });
      console.log("forgot password");
    },
  }
}
</script>

<style scoped>

</style>
