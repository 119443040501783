<template>

  <div id="is-logged-in" data-is-logged-in="false"></div>
  <nav-bar-header/>
  <sidebar/>
  <router-view :key="$route.fullPath"></router-view>

  <nav-bar-footer/>
</template>

<script>

import NavBarHeader from "@/components/NavBarHeader";
import NavBarFooter from "@/components/NavBarFooter";
import Sidebar from "@/components/Sidebar";
import {onMounted} from "vue";
export default {
  name: 'App',
  components: {
    NavBarHeader,
    NavBarFooter,
    Sidebar,
  },
  setup() {
    onMounted(() => {
      var sidebarSensitiveDivs = document.querySelectorAll(".sidebar-sensitive");
      for (let i = 0; i < sidebarSensitiveDivs.length; i++) {
        sidebarSensitiveDivs[i].addEventListener('click', function (event) {
          NavBarHeader.methods.closeNav(event);
        });
      }
    })
  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
