<template>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent is-justify-content-center">
      <br>
      <br>
      <div class="tile is-child"></div>
      <div class="panel notification">
        <div class="panel-heading notification is-danger">
          Reset your password.
        </div>
        <form v-on:submit.prevent="resetPassword">
          <div class="content">
            <div class="field">
              <p class="text-danger"></p>
              <p class="control has-icons-left has-icons-right ">

                <input class="input" type="password" placeholder="Password" id="password" name="password" v-model="password">
                <span class="icon is-small is-left">
                                  <i class="fas fa-envelope"></i><font-awesome-icon :icon="['fas', 'lock']" />
                                  </span>
                <span class="icon is-small is-right">
                                  <i class="fas fa-check"></i>
                                  </span>
              </p>
            </div>
            <div class="field">
              <p class="text-danger"></p>
              <p class="control has-icons-left has-icons-right ">

                <input class="input" type="password" placeholder="Confirm Password" id="confirmPassword" name="password" v-model="confirmPassword">
                <span class="icon is-small is-left">
                                  <i class="fas fa-envelope"></i><font-awesome-icon :icon="['fas', 'lock']" />
                                  </span>
                <span class="icon is-small is-right">
                                  <i class="fas fa-check"></i>
                                  </span>
              </p>
            </div>
            <div><p class="text-danger">{{ response_msg }}</p></div>
            <div><button class="button is-danger" type="submit">Reset Password</button></div>
          </div>


        </form>
      </div>
      <div class="tile is-child"></div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      response_msg: '',
    };
  },
  methods: {
    resetPassword() {
      let uuid = this.$route.query.id;
      console.log(uuid);
      if (this.password === this.confirmPassword) {
        let url = '/reset';
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: uuid,
            password: this.password
          })
        }).then(response => {
          if (response.ok) {
            this.response_msg = 'Password reset successfully';
          } else {
            this.response_msg = 'Password reset failed';
          }
        });
      } else {
        this.response_msg = 'Passwords do not match';
      }
      console.log(uuid);
    }
  }
}
</script>

<style scoped>

</style>
