<template>
  <section class="hero">
    <div class="hero-body sidebar-sensitive">
        <div class="container">
        <h1 class="title">
           Alabang Venture Exchange Automated Quotation
        </h1>
        <h2 class="subtitle">
            An unbeatable equities market recommendation engine
        </h2>
        </div>
    </div>
    <main-tile-panel/>
  </section>

</template>
<script>
import { onMounted } from 'vue';
import NavBarHeaderVue from "@/components/NavBarHeader";
import MainTilePanel from "@/components/MainTilePanel";
export default {
  name: 'Hero',
  components: {
    MainTilePanel,
  },
  props:
    {
    msg: String
  }
  , title: 'Beef',
  setup() {
    onMounted(() => {
      var sidebarSensitiveDivs = document.querySelectorAll(".sidebar-sensitive");
      for (let i = 0; i < sidebarSensitiveDivs.length; i++) {
        sidebarSensitiveDivs[i].addEventListener('click', function(event) {
          NavBarHeaderVue.methods.closeNav(event);
        });
      }
    })
  }
}
</script>
