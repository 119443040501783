<template>
 <!-- add 4 bulma panelS -->
<div class="section">
  <div class="columns">
    <div class="column">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <h2 class="subtitle is-3">We use the power of the crowd to make investing easy</h2>
                  <p class="subtitle is-5 has-text-justified">{{ firstSection }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <img class="image is-fullwidth" :src="image.backgroundImage" alt="People talking" >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section">
  <div class="columns">
    <div class="column">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <img class="image is-fullwidth" :src="image.secondImage">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child">
            <div class="card">
              <div class="card-content">
                <div class="content">

                  <h2 class="subtitle is-3">With unmatched reliable AI power</h2>
                  <p class="subtitle is-5 has-text-justified">{{ secondSection }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import hwit from '@/components/howitworks';
export default {

  name: "HowitWorks",
  data() {
    return {
      /** an image of people talking , fit to panel*/
      image: {
        backgroundImage: "https://www.verywellmind.com/thmb/QGS470MKXc4IEqpbQl3qm1bUsuQ=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-1057500046-f7e673d3a91546b0bd419c5d8336b2e0.jpg",
        secondImage: "https://cdn.pixabay.com/photo/2021/01/15/07/33/woman-5918637__340.jpg"
      },
      firstSection: hwit.getFirstSection(),
      secondSection: hwit.getSecondSection(),
    };
  }
}
</script>

<style scoped>

</style>
