<script>
  // setup properties
  export default {
    name: "StockCardModal",
    props: {
      name: String,
      symbol: String,
      amount: Number,
      price: Number,
      change: String,
      volume: String,
      as_of: String,
      unicorn: Boolean
    },
    setup() {
      // setup methods
      const close = () => {
        // close modal
        document.querySelector(".modal").classList.remove("is-active");
      };
      return { close };
    },

  }
</script>

<template>
  <!-- Create a stock card modal using bulma css -->
  <div>
    <div class="modal" >
      <div class="modal-background" @click="close"></div>
      <div class="modal-content">
        <div class="box">
          <div class="columns">
            <div class="column">
              <p class="title">Symbol</p>
              <p class="subtitle">{{ symbol }}</p>
            </div>
            <div class="column">
              <p class="title">As of</p>
              <p class="subtitle">{{ as_of }}</p>
              </div>
            <div class="column">
              <p class="title">Volume</p>
              <p class="subtitle">{{ volume?.toLocaleString() }}</p>
            </div>
            <div class="column">
              <p class="title">Price</p>
              <p class="subtitle">{{ amount }}</p>
            </div>
            <div class="column">
              <p class="title">Change</p>
              <p class="subtitle">{{ change }}%</p>
            </div>

          </div>

          <div class="columns">
            <div class="column">
              <p class="title">Recommended action:</p>
              <p class="subtitle">Buy at the current price and exit at your target price.</p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <p class="title">Risk Level:</p>
              <p class="subtitle">

                <span v-if="unicorn" class="span-green-bold">Unicorn</span>
                <span v-else>Normal</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: 1.5rem;
  font-weight: bold;
}
 .span-green-bold {
   color: green;
   font-weight: bold;
 }

</style>
