/**
 * Copywrite is generated dynamically by Github Copilot
 * @returns {string}
 */
exports.getFirstSection = function () {
  let firstSection ='Our unbeatable system takes near-real time data from Philippine Stock Exchange ' +
    'during trading hours and uses it to generate a list of stocks that are likely to ' +
    'increase in value. The system is based on a proprietary algorithm that has been ' +
    'tested and proven to be highly accurate. The system is designed to be used by ' +
    'anyone, even those with no prior knowledge of the stock market. ';
  return firstSection;
}
/**
 * Copywrite is generated dynamically by Github Copilot
 * @returns {string}
 */
exports.getSecondSection = function () {
  let secondSection = 'For analysts, our automated quote system is a powerful tool that can ' +
    'provide a more reliable recommendation for stocks to buy or sell. The system ' +
    'is based on our patent-pending algorithm designed to take the pulse of the market sentiment' +
    'during trading hours. For brokers, our report generation tool is unmatched in the market.' ;
  return secondSection;
}
