<template>
  <div class="card has-background-warning box">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <article class="card-body has-text-left">
              <p class="title">
                {{percentChange}}
              </p>
            </article>
          </div>
          <div class="column">
            <article class="has-text-left">
              <p class="title">
                {{symbol}}
              </p>
            </article>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column ">
          <article class="has-text-left">
            <p class="subtitle is-size-4">
               {{name}}
            </p>
            <p class="subtitle">
              {{timestamp}}
            </p>
          </article>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "CarouselCard",
  props: {
    percentChange: String,
    symbol: String,
    name: String,
    timestamp: String,
  }
}
</script>

<style scoped>

</style>
