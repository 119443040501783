import {
  createApp
} from 'vue'
import App from './App.vue'
import 'bulma/css/bulma.css'
import './assets/sidebar.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import {faLock, faNewspaper} from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faPerson } from "@fortawesome/free-solid-svg-icons"
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { createRouter , createWebHashHistory }from 'vue-router'
import Hero from "@/components/Hero";
import Login from "@/components/Login"
import Register from "@/components/Register";
import Homepanel from "@/components/Homepanel";
import HowitWorks from "@/components/HowitWorks.vue";
import Pricing from "@/components/Pricing.vue";
import { createPinia } from 'pinia';
import ForgotPassword from "@/components/ForgotPassword.vue";
import PasswordReset from "@/components/PasswordReset.vue";


library.add(faEnvelope, faPen);
library.add(faTwitter, faLock, faUser, faHouse, faPhone, faCalendar, faPaperPlane,
  faUpload, faGlobeAmericas, faGithub, faPerson, faUser, faNewspaper);

const routes = [
  {
    path: '/',
    name: 'Hero',
    component: Hero
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/home',
    name: 'Home',
    component: Homepanel,
  },
  {
    path: '/howitworks',
    name: 'HowitWorks',
    component: HowitWorks,
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
  },
  { path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  { path: '/reset',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/components/Contact.vue')
  },
  {
    path: '/tirador',
    name: 'Tirador',
    component: () => import('@/components/Tirador.vue')
  }
]
const router = createRouter ({
  history: createWebHashHistory(),
  routes,
})
const pinia = createPinia();
const app = createApp(App)
app.use(router)
app.use(pinia)

app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
