<template>
<!-- Home panel is displayed after successful login -->
  <div class="box">
    <section class="columns">
      <div class="column">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <div class="tile is-child">
              <div class="notification is-primary">

                <TopWidgets/>
              </div>

            </div>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <div class="tile is-child">
              <div class="notification is-info">
                <p class="has-text-white">
                  {{ stockData.length > 0 ? 'Recommendation for free subscribers' : "No recommendations found or Market is closed" }}

                </p>
                <Transition name="bounce">
                  <div class="columns is-multiline">
                    <div class="column is-3" v-for="stock in stockData" :key="stock.symbol">
                      <div class="card bounce-enter-active">
                        <header class="card-header orange">
                          <div class="card-header-title">
                            <p>{{ stock.name }}</p>
                          </div>
                        </header>
                        <div class="card-content orange">
                          <div class="subtitle">{{ stock.symbol }}</div>
                          <div class="content has-background-orange">
                            <div class="label">Change:</div> <div>{{ stock.change }}%</div>
                            <div class="label">Closing Price:</div> <div>{{ stock.amount }}</div>
                            <div class="label">Volume:</div> <div>{{ stock.volume?.toLocaleString() }}</div>
                            <div class="label">Last Updated:</div> <div>{{ stock.as_of }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>

              </div>
            </div>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <div class="tile is-child">
              <div class="notification is-warning table-container">
                <p class="has-text-white has-text-weight-bold">
                  {{ swagList.length > 0 ? "Premium SWAG List" : "No recommended SWAG as of the moment or Market is closed" }}
                  <!-- {{ swagList }} Bulma table here -->

                </p>
                <div v-if="subscribed === 'YES'">
                  <table class="table is-fullwidth">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Symbol</th>
                      <th>Change</th>
                      <th>Closing Price</th>
                      <th>Volume</th>
                      <th>Last Updated</th>
                      <th></th>
                      <th></th>
                    </tr>

                    </thead>
                    <tbody class="has-text-left">
                    <tr v-for="item in swagList" :key="item.symbol">
                      <td>{{ item.name }}</td>
                      <td id="symbol">{{ item.symbol }}</td>
                      <td>{{ item.change }}%</td>
                      <td>{{ item.amount }}</td>
                      <td>{{ item.volume.toLocaleString() }}</td>
                      <td>{{ item.as_of }}</td>
                      <td><button class="button is-primary" @click="openStockModal(item.symbol)">More info</button></td>
                      <td><button class="button is-warning">Trade now</button></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <article>
                    <div class="message is-warning">
                      <div class="message-header">
                        <p>Subscribe to get premium SWAG list</p>
                      </div>
                      <div class="message-body">
                        <p><router-link to="/pricing"> Subscribe to get premium SWAG list</router-link></p>
                      </div>
                    </div>
                  </article>
                </div>
                <StockCardModal :symbol="modalData.symbol" :amount="modalData.amount" :price="modalData.price"
                                :change="modalData.change" :volume="modalData.volume" :as_of="modalData.as_of" :unicorn="modalData.unicorn"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <div class="tile is-child">
              <div class="notification">
                <div class="is-left">
                  <div class="scrollable-container">
                    <p class="has-text-black has-text-weight-bold">Comments</p>
                    <Comments/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>



</template>

<script>
import TopWidgets from "@/components/TopWidgets";
import {onMounted} from "vue";
import {useUserStore} from "@/stores/UserStore";
import StockCardModal from "@/components/StockCardModal.vue";
import Comments from "@/components/Comments.vue";

export default {
  name: "Homepanel",
  components: {StockCardModal, TopWidgets, Comments},
  data() {
    const userStore = useUserStore();
    return {
      topMsg: "Top Tile",
      stockData: {},
      swagList: [],
      sideMsg: "Side Message "
        + "\n\n with a lot of \n lines",
      userStore,
      subscribed: 'NO',
      modalData: {},
    }
  },
  setup() {
    onMounted (() => {
      console.log("Homepanel mounted");

    })
  },

    methods: {

    openStockModal(symbol) {

      this.searchStock(symbol)
      //show Stock Modal with the stock info of the selected stock symbol
      //check first if the classlist is not null
      if (document.querySelector(".modal").classList) {
        document.querySelector(".modal").classList.add("is-active");
      }

    },
    searchStock(symbol) {
      //search for the stock symbol
      console.log("searchStock() called");
      for (let i = 0; i < this.swagList.length; i++) {
        if (this.swagList[i].symbol === symbol) {

          this.modalData = this.swagList[i];
          break;
        }
      }
      //check if this is a unicorn stock by calling the backend API
      //if it is a unicorn stock, display a unicorn icon
      //if it is not a unicorn stock, display a horse icon
      let url = '/get_stock_info?stock=' + symbol;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.unicorn) {

            this.modalData.unicorn = true;

            console.log("This is a unicorn stock");
          } else {
            this.modalData.unicorn = false;

            console.log("This is not a unicorn stock");
          }
        })
        .catch(error => {
          console.log(error);
        });

    },

       /**
       * This method is called when the user clicks on the
       * "Fetch PSE Stocks" button
        *
       */

      fetch_pse_stocks()
      {
        console.log(localStorage.getItem("isLoggedIn"));
        if (localStorage.getItem("isLoggedIn") === "true") {
          this.userStore.$state.isLoggedIn = true;
        }
        if (localStorage.getItem("subscribed")) {
          this.subscribed = 'YES';
        }
        let url = '/fetch_pse_stocks';
        if (this.userStore.$state.isLoggedIn){
          fetch(url)
            .then(response => response.json())
            .then(data => {
              this.stockData = data[0];
              this.swagList = data[1];
            })
            .catch(error => {
              console.log(error);
            });
        }
        console.log("fetch_pse_stocks() called");
        }
      },
    created() {
      console.log("Fetching stocks");
      this.fetch_pse_stocks();
      setInterval(this.fetch_pse_stocks, 10000);
      //this.fetch_pse_stocks();
    },

}

</script>

<style scoped>
.card-header.orange{
  background-color: #FFBF00;
}

.card-content.orange {
  background-color: #FFAC1C;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.scrollable-container {
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto;
}
</style>
