<template>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <div class="tile is-child">
        <div class="columns">
          <div class="column">
            <div class="card">
              <header class="card-header orange">
                <div class="card-header-title">Weather</div>
              </header>
              <div class="card-content orange">
                <Weather/>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="card">
              <div class="card-header orange">
                <div class="card-header-title">Newsfeed</div>
              </div>
              <div class="card-content orange">
                <p class="content">
                  Card content
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="card">
              <div class="card-header orange">
                <div class="card-header-title">Travel</div>
              </div>
              <div class="card-content orange">
                <p class="content">
                  {{ msg }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Weather from "./Weather.vue";
export default {
  name: "TopWidgets",
  components: {Weather},
  data() {
    return {
      msg: "Card content from msg"
    }
  }
}
</script>

<style scoped>

.card-header.orange{
  background-color: #FFBF00;
}

.card-content.orange {
  background-color: #FFAC1C;
}

</style>
