<template>
  <div>
    <div class="new-comment">
     <textarea
       class="textarea"
       placeholder="Post New Comment Here"
       rows="10"
       v-model="newComment"
       @keydown="handleKeyDown"></textarea>
    </div>
    <div v-for="(comment, index) in comments.slice(0,10)" :key="index">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img class="avatar" src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
          </figure>
        </div>
        <div class="media-content">
          <div class="user-info">
            <strong>{{ comment.about }}</strong>
            <br>
            <small>@{{ comment.username }}</small>
            <br>
            <small>{{ comment.timestamp }}</small>
          </div>
          <div class="content">
            <p>{{ comment.message }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { useUserStore } from "@/stores/UserStore";
import {ref, onMounted} from "vue";

export default {
  data() {
    const userStore = useUserStore();
    return {
      newComment: '',
      comments: [
        {message: 'This is a comment.', editing: false, subscribed: true, timestamp: moment(new Date()).fromNow()},
        {message: 'This is another comment.', editing: false, subscribed: false, timestamp: moment(new Date()).fromNow()},
        // Add more comments as needed
      ],
      userStore: userStore,
    };
  },
  created() {
    this.loadCommentsFromRedis();
  },
  methods: {
    handleKeyDown(e) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        this.postComment();
      }
    },
    postComment() {
      const user = this.userStore.$state.username;
      const comment = {
        message: this.newComment,
        user: this.userStore.$state.username,
        about: 'Comments from ' + user,
      };
      console.log("Posting commment from user: " + user + " about: " + comment.about + " message: " + comment.message);
      fetch('/post_comment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(comment),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.comments.unshift({
            ...data,
            editing: false,
            timestamp: moment(data.timestamp).fromNow()
          });
          this.newComment = '';
          this.loadCommentsFromRedis();
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation: ', error);
        });
    },
    editComment(index) {
      this.comments[index].editing = true;
    },
    saveComment(index) {
      this.comments[index].editing = false;
      // Save the updated comment
      // This could be an API call or some other method of saving the comment
    },
    loadCommentsFromRedis() {
      setInterval(() => {
        fetch('/fetch_comments')
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            this.comments = data.map(comment => ({
              ...comment,
              editing: false,
              timestamp: moment(comment.timestamp).fromNow()
            }));
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation: ', error);
          });
      }, 5000);
    }

  },
  setup() {

    const subscribed = ref(localStorage.getItem('subscribed') ? JSON.parse(localStorage.getItem('subscribed')) : 'NO');
    onMounted(() => {
      localStorage.setItem('subscribed', subscribed.value);
    });

    return {  subscribed };
  },
};
</script>

<style scoped>
/* Add any necessary CSS here */
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 10px;
}
.textarea.is-rounded {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.new-comment {
  margin-bottom: 20px; /* Adjust as needed */
}
.user-info {
  margin-bottom: 10px;
  color: #888;
}
.content {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
}
</style>
