<template>
    <div>
        <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <div class="navbar-item">
            <div class="buttons">
            <a class="button is-primary" @click="openNav" :aria-pressed="clicked ? 'true' : closeNav">
                <strong>Menu</strong>
            </a>

            </div>
        </div>
        <div class="navbar-item sidebar-sensitive">

        </div>
        <!-- <div class="sidebar-sensitive level"> -->
<!--            <a class="navbar-item" href="/">
                <img src="/steak-mafia.png" >
            </a>-->

            <a role="button" class="navbar-burger navbar-end sidebar-sensitive" aria-label="menu" aria-expanded="false"  data-target="navbarBasicExample" @click="openMenu" >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        <!-- </div> -->
    </div>

    <div id="navbar_menu" class="navbar-menu sidebar-sensitive" >
        <div class="navbar-start">
        <router-link to="/" class="navbar-item">Home</router-link>

        <router-link to="/pricing" class="navbar-item">Pricing</router-link>

        <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
            More
            </a>

            <div class="navbar-dropdown">
              <router-link to="/howitworks" class="navbar-item">How it Works</router-link>


            <router-link to="/contact" class="navbar-item">
                Contact
            </router-link>
            <hr class="navbar-divider">
            <a class="navbar-item">
                Report an issue
            </a>
            </div>
        </div>
          <div class="navbar-item">
            <router-link to="/tirador" class="navbar-item">Tirador</router-link>
          </div>
        </div>

        <div class="navbar-end">
        <div class="navbar-item">
<!-- TODO add circular buttons-->
          <div class="buttons are-medium field is-grouped">
            <router-link class="button group is-primary" to="/home">

                  <span class="icon">
                    <font-awesome-icon icon="fa-regular fa-user"/>
                  </span>

            </router-link>
            <router-link class="button group is-info" to="/register">

                  <span class="icon">
                    <font-awesome-icon icon="fa-solid fa-pen"/>
                  </span>

              </router-link>

            <router-link to="/login"><button class="button group has-background-warning-light">
                <span class="icon">
                   <font-awesome-icon icon="fa-solid fa-lock"/>
                </span>

              </button> </router-link>
          </div>
        </div>
        </div>
      </div>
    </nav>
  </div>

</template>
<script>
import { onMounted } from '@vue/runtime-core';

export default {
    name: 'NavBarHeader',

    setup () {
        onMounted(() => {
            let gumroad = document.createElement('script');
            gumroad.setAttribute('src', 'https://jared201.gumroad.com/l/beef');
            document.head.appendChild(gumroad);
        })
    },
    data() {
        return {
          clicked: false,
          is_active: "",

        }

    },
    components : {

    },
    methods: {
        openNav(event){
            if (!this.clicked || document.getElementById("sidebar").style.width=='0px'){
                document.getElementById("sidebar").style.width='250px';
                //document.getElementById("app").style.marginLeft = "250px";
                console.log(event.target);
                this.clicked = true;
            } else {
                this.closeNav(event);
            }

        },
        closeNav(event){
            document.getElementById("sidebar").style.width = "0px";
            //document.getElementById("app").style.marginLeft = "0px";
            console.log("closeNav " + event.target);
            this.clicked = false;
            console.log(this.clicked);
        },
        openMenu(event){
            console.log(event.target);
            this.is_active = "is-active";
            console.log(this.is_active)
            const element = document.getElementById("navbar_menu")
            element.classList.toggle("is-active") //<--- use something that fucking works!
        }
    }
}
</script>

<style scoped>


.button.is-primary {
    background-color: #d59a57;
    border-color: transparent;
    color: #fff;
}

.button.button.is-primary:hover {
    background-color: #d59a57;
}
.button.group {
  border-radius: 50%;
}
</style>
