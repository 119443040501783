<template >
<div class="has-background-grey-light">
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <img :src="banner">
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent is-justify-content-center">
      <div class="tile is-child"></div>
      <div class="panel notification">
        <div class="notification panel-heading is-info">
          Login
        </div>
        <form v-on:submit.prevent="login()">
          <div class="content">
            <router-link to="/register" >No account yet? Register here</router-link>
            <div class="field">
              <p class="text-danger"></p>
              <p class="control has-icons-left has-icons-right ">

                <input class="input" type="text" placeholder="Username" id="username" name="username" v-model="username">
                <span class="icon is-small is-left">
                                  <i class="fas fa-envelope"></i><font-awesome-icon :icon="['far', 'user']" />
                                  </span>
                <span class="icon is-small is-right">
                                  <i class="fas fa-check"></i>
                                  </span>
              </p>
            </div>
            <div class="field">
              <p class="control has-icons-left">
                <input class="input" type="password" placeholder="Password" id="password" name="password" v-model="password">
                <span class="icon is-small is-left">
                                  <i class="fas fa-lock"></i><font-awesome-icon :icon="['fas', 'lock']" />
                                  </span>
              </p>
            </div>
            <div><p class="has-text-danger-dark" v-if="login_error">{{ errorMsg }} , <router-link to="/forgotpassword">
              Forgot Password?</router-link></p>
            </div>
            <div>
              <button class="is-danger button" type="submit">Login</button>
            </div>
          </div>
        </form>
      </div>
      <div class="tile is-child"></div>
    </div>
    <div class="modal" id="login-modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ card_title }}</p>
          <button class="delete" aria-label="close" @click="closeModal"></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <article class="has-text-weight-bold">
            Hey! You're still logged in! Are you sure you want to log out?
          </article>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="logout">Logout</button>
          <button class="button" @click="closeModal">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <br>
      <br>
      <div class="tile is-child">
        <br>
      </div>
      <br>
    </div>
  </div>
</div>
</template>

<script>
import {useUserStore} from "@/stores/UserStore";
import {onMounted} from "vue";

export default {
  name: "Login",
  setup() {
    onMounted(() => {
      console.log("mounted")
      const isLogged = localStorage.getItem("isLoggedIn");
      if (isLogged === "true") {
        const modal = document.getElementById("login-modal");
        modal.classList.add("is-active");
      }

    })
  },
  data () {
    const userStore = useUserStore();
    return {
      username: '',
      password: '',
      banner: require('/public/alabangvx-logo.png'),
      session: {},
      errorMsg:'',
      userStore,
      card_title: 'Login',
      forgot_password_link: '/forgotpassword',
      login_error : false,
    }
  },
  watch: {
    session: function (val) {
      this.$emit('update:session', val);
    },
    errorMsg: function (val) {
      this.$emit('update:errorMsg', val);
    }
  },
  methods: {
    logout() {
      this.userStore.$state.isLoggedIn = false;
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("subscribed");
      this.$router.push({name: 'Hero'});
    },
    openModal() {
      const modal = document.getElementById("login-modal");
      modal.classList.add("is-active");
    },
    closeModal() {
      const modal = document.getElementById("login-modal");
      modal.classList.remove("is-active");
      this.$router.push({name: 'Home'});
    },
    login () {
      console.log('login')
      fetch('/dash',
        {method: 'post',
          headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
          body: JSON.stringify({username: this.username, password: this.password})})
        .then(res => res.json())
        .then(data => {
          console.log(data);
          console.table(data);
          if (data.login_error !== '') {
            this.userStore.$state.isLoggedIn = false;

            this.errorMsg = data.login_error;
            this.login_error = true;
          } else {
            this.userStore.$state.isLoggedIn = true;
            this.userStore.$state.username = data.username;
            const div = document.getElementById('is-logged-in');
            localStorage.setItem('isLoggedIn', 'true');
            if (data.subscribed == 'YES') {
              localStorage.setItem('subscribed', 'true');
            }

            div.dataset.isLoggedIn = 'true';
            this.session = data;
            this.$router.push('/home');
          }
        })
        .catch(function (error) {
          this.login_error = true;
          console.log('There has been a problem with your fetch operation: ' + error.message)
        })
    }
  }

}
</script>

<style scoped>

</style>
